<template>
  <div class="auth-wrapper auth-v1 px-4">
    <div class="auth-inner py-2">
      <b-card class="mb-0 p-2 pt-3 pb-3">

        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo/>

          <h2 class="brand-text text-primary ml-1">
            {{ getAppName() }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $i18n.t('verify_page.title') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $i18n.t('verify_page.message') }}
        </b-card-text>

        <!-- email -->
        <b-form-group
            :label="$i18n.t('Email')"
            label-for="email"
        >
          <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
          >
            <b-form-input
                id="email"
                v-model="email"
                :state="errors.length > 0 ? false:null"
                name="email"
                placeholder="example@example.com"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- button -->
        <b-button
            block
            variant="primary"
            @click="resend"
        >
          {{ $i18n.t('verify_page.button') }}
          <b-spinner
              v-if="isLoading"
              small
          />
        </b-button>

        <p class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon"/>
            {{ $i18n.t('Back to login') }}
          </b-link>
        </p>
      </b-card>
    </div>
  </div>

</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      isLoading: false,
      email: ''
    }
  },
  mounted() {
    this.email = this.$route.query.email
  },
  methods: {
    resend() {
      if (!this.isLoading) {
        this.isLoading = true
        this.$http.post('/email/verify/resend', {
          email: this.email
        }).then(response => {
          this.isLoading = false
          this.$router.replace('/login')
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$i18n.t('Success'),
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
              })
        }).catch(error => {
          this.isLoading = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style>
.auth-wrapper.auth-v1 .auth-inner {
  max-width: 500px;
}
</style>
